<template>
  <div style="padding-bottom: 4.5%">
    <div v-if="isLoading">
      <OtherLoading />
    </div>
    <div v-else>
      <b-form>
        <div>
          <h1 class="mr-sm-4 header-tablepage">News/Promotion News Detail</h1>
        </div>
        <div class="title-panel mt-3">Promotion Info</div>
        <b-container class="no-gutters bg-white">
          <div class="pt-3">
            <b-row>
              <b-col md="6">
                <InputText
                  textFloat="Name"
                  placeholder="Name"
                  type="text"
                  name="promotionname"
                  isRequired
                  :v="$v.form.promotion.name"
                  :isValidate="$v.form.promotion.name.$error"
                  v-model="form.promotion.name"
                />
              </b-col>
              <b-col md="6" class="mb-3">
                <div class="d-flex align-items-center">
                  <span class="label">
                    Promotions Type <span class="text-danger">*</span></span
                  >
                </div>
                <InputSelect
                  title=""
                  name="Promotions Type"
                  isRequired
                  v-model="form.promotion.mobile_news_type_id"
                  :isValidate="$v.form.promotion.mobile_news_type_id.$error"
                  v-bind:options="promotionList"
                  valueField="id"
                  textField="name"
                  :noPleaseSelect="noPleaseSelect"
                  :disabled="isEdit"
                  :v="checkrequired"
                  @onDataChange="handleChangeType"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <InputTextArea
                  textFloat="Short Description"
                  placeholder="Short Description"
                  isRequired
                  :value="form.promotion.short_description"
                  :v="$v.form.promotion.short_description"
                  :isValidate="$v.form.promotion.short_description.$error"
                  v-model="form.promotion.short_description"
                  rows="3"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col md="6">
                <UploadFile
                  textFloat="Cover Image"
                  placeholder="Please choose file"
                  format="all"
                  :fileName="form.promotion.image_url"
                  v-model="form.promotion.image_url"
                  name="thumbnail"
                  text="*Please upload only .png, .jpg image with ratio 1:1"
                  isRequired
                  :accept="acceptType"
                  :v="$v.form.promotion.image_url"
                  v-on:onFileChange="onImageChange"
                  v-on:delete="deleteImage"
                />
              </b-col>
              <b-col md="6" class="mt-3">
                <div
                  class="preview-box b-contain"
                  v-bind:style="{
                    'background-image': 'url(' + showPreview + ')',
                  }"
                >
                  <img
                    src="@/assets/icons/loading.svg"
                    class="loading"
                    alt="loading"
                    v-if="isLoadingImage"
                  />
                  <font-awesome-icon
                    icon="times-circle"
                    class="text-secondary delete-icon pointer"
                    v-if="form.promotion.image_url"
                    @click="deleteImage"
                  />
                </div>
              </b-col>
              <b-col md="12" class="mt-3 mb-3">
                <b-button v-b-toggle.collapse-images class="collapse-btn d-flex align-items-center justify-content-between">
                  <span class="img-note label">
                    Images Support only .jpg, .png for image (Maximum 10
                    MB)</span
                  >
                  <font-awesome-icon
                    icon="chevron-down"
                    class="icon float-right mt-1 when-opened"
                  />
                  <font-awesome-icon
                    icon="chevron-left"
                    class="icon float-right mt-1 when-closed"
                  />
                </b-button>
                <b-collapse id="collapse-images" visible>
                  <b-card class="card-box mb-0">
                    <MultipleImages
                      @updateImageList="updateImageList"
                      :dataList="form.promotion.imageList"
                      :v="$v.form.promotion.imageList"
                      @deleteImagesList="deleteImgList"
                    />
                    <div v-if="form.promotion.productTypeId == 2">
                      <label class="font-weight-bold main-label mb-3">
                        {{ form.promotion.children.label }}
                      </label>
                      <template v-if="forceRefresh">
                        <draggable
                          :list="form.promotion.productAttributeImage"
                          handle=".handle"
                          class="img-list m-0"
                        >
                          <div
                            class="handle"
                            v-for="(img, index) in form.promotion
                              .productAttributeImage"
                            :key="index"
                          >
                            <ImageUpload
                              v-bind:dataFile="img"
                              :index="index"
                              :name="img.altTag"
                              :v="
                                $v.form.promotion.productAttributeImage.$each
                                  .$iter[index].image_url
                              "
                              @handleChangeImage="handleChangeImage"
                            />
                          </div>
                        </draggable>
                      </template>
                    </div>
                  </b-card>
                </b-collapse>
              </b-col>
              <b-col md="12">
                <TextEditorsTiny
                  textFloat="Description"
                  :rows="4"
                  :value="form.promotion.description"
                  :name="'description_' + 1"
                  placeholder="Type something..."
                  isRequired
                  :v="$v.form.promotion.description"
                  @onDataChange="(val) => (form.promotion.description = val)"
                />
              </b-col>
              <b-col> </b-col>
            </b-row>
          </div>
        </b-container>
        <div class="no-gutters bg-white mt-3 py-2 px-3">
          <b-form-checkbox
            switch
            v-model="form.promotion.status"
            class="radio-active"
            size="lg"
            :value="1"
            :unchecked-value="0"
          >
            <span class="ml-2 main-label">{{
              form.promotion.status ? "Active" : "Inactive"
            }}</span>
          </b-form-checkbox>
        </div>
        <FooterAction @submit="checkForm(0)" routePath="/promotionnews" />
      </b-form>
    </div>
  </div>
</template>

<script>
import InputText from "@/components/inputs/InputText";
import UploadFile from "@/components/inputs/UploadFile";
import OtherLoading from "@/components/loading/OtherLoading";
import InputSelect from "@/components/inputs/InputSelect";
import TextEditorsTiny from "@/components/inputs/TextEditorsTiny";
import MultipleImages from "@/components/inputs/MultipleImages";
import { required } from "vuelidate/lib/validators";
import InputTextArea from "@/components/inputs/InputTextArea";
export default {
  name: "PromotionDetails",
  components: {
    InputText,
    InputSelect,
    MultipleImages,
    UploadFile,
    TextEditorsTiny,
    InputTextArea,
    OtherLoading,
  },
  data() {
    return {
      id: this.$route.params.id,
      isLoading: false,
      isDisable: false,
      isEdit: false,
      noPleaseSelect: true,
      isLoadingImage: false,
      coverImgType: 1,
      acceptType: "image/jpeg, image/png",
      showPreview: "",
      promotionList: [],
      form: {
        promotion: {
          id: 0,
          mobile_news_type_id: 0,
          name: "",
          description: "",
          is_vedeo: "",
          image_url: "",
          status: 0,
          Base64String: "",
          imageList: [],
          short_description: "",
        },
      },
      image: {
        size: "",
        height: "",
        width: "",
      },
      checkrequired: {
        required: null,
      },
      deleteImageList: [],
    };
  },
  validations: {
    form: {
      promotion: {
        name: { required },
        mobile_news_type_id: { required },
        description: { required },
        image_url: { required },
        status: { required },
        short_description: { required },
      },
    },
  },
  created: async function () {
    await this.getDatas();
  },
  methods: {
    getDatas: async function () {
      if (this.id !== "0") {
        await this.$store.dispatch("ActionGetNewByID", this.id);
        var data = await this.$store.state.moduleConnectAPI.stateNewByID;
        if (data.result == 1) {
          this.form.promotion = data.detail;
          if (data.detail.status === 0) {
            this.form.promotion.status = false;
          } else {
            this.form.promotion.status = true;
          }
          this.$v.form.$reset();
          if (this.form.promotion.id > 0) {
            this.isEdit = true;
            this.showPreview = this.form.promotion.image_url;
          }
        }
        this.updateImageList(data.detail.imageList);
        this.GetNewsType();
      } else {
        this.GetNewsType();
      }
    },
    async GetNewsType() {
      await this.$store.dispatch("ActionGetNewsType");
      var data = await this.$store.state.moduleConnectAPI.stateGetNewsType;
      if (data.result == 1) {
        this.promotionList = data.detail;
      }
    },
    handleChangeType: async function (value) {
      this.form.promotion.mobile_news_type_id = value;
    },
    handleStatus(value) {},
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    updateImageList(list) {
      this.form.promotion.imageList = list;
    },
    onImageChange(val) {
      this.isLoadingImage = true;
      this.isDisable = true;

      var reader = new FileReader();
      reader.readAsDataURL(val);
      reader.onload = async () => {
        let img = new Image();
        img.onload = () => {
          this.image.width = img.width;
          this.image.height = img.height;
          if (parseInt(this.image.height) !== parseInt(this.image.width)) {
            this.$swal("Please upload image with ratio 1:1 !", {
              icon: "info",
            });
            this.deleteImage();
          }
        };
        img.src = await reader.result;
        this.isLoadingImage = false;
        this.isDisable = false;
        this.showPreview = reader.result;
        this.form.promotion.image_url = reader.result;
        this.form.promotion.Base64String = reader.result;
        this.form.promotion.is_vedeo = false;
      };
    },
    deleteImage() {
      this.form.promotion.image_url = null;
      this.form.promotion.Base64String = null;
      this.showPreview = null;
    },
    checkForm: async function (e) {
      this.$v.form.$touch();
      if (this.form.promotion.mobile_news_type_id === 0) {
        this.checkrequired.required = false;
      } else {
        this.checkrequired.required = true;
      }
      if (this.$v.form.$error) {
        return;
      }
      this.submit();
    },
    deleteImgList(id) {
      this.deleteImageList.push(id);
    },
    submit: async function () {
      this.isDisable = true;
      let data = null;

      if (this.id !== "0") {
        this.form.promotion.deleteImageList = this.deleteImageList;
        await this.$store.dispatch("ActionUpdatetNews", this.form.promotion);
        data = await this.$store.state.moduleConnectAPI.stateUpdatetNews;
        if (data.result == 1) {
          this.isDisable = false;
          this.$swal("Update Success!", {
            icon: "success",
          }).then(function () {
            window.location.href = "/promotionnews";
          });
        }
      } else {
        this.form.promotion.image_url = "";
        await this.$store.dispatch("ActionCreateNews", this.form.promotion);
        data = await this.$store.state.moduleConnectAPI.stateCreateNews;
        if (data.result == 1) {
          this.isDisable = false;
          this.$swal("Create Success!", {
            icon: "success",
          }).then(function () {
            window.location.href = "/promotionnews";
          });
        }
      }
    },
  },
};
</script>

<style scoped>
.card-box {
  padding: 0.25rem;
  border-top-left-radius: 0%;
  border-top-right-radius: 0%;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.img-note {
  font-size: 12px;
  margin-left: 5px;
  width: 300px;
  display: contents;
  /* white-space: nowrap; */
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  top: 4px;
}
.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
  display: none;
}
.label {
  color: #212529;
  font-size: 16px;
  margin-bottom: 2px;
  font-weight: bold;
}
</style>
